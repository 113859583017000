<template>
  <div class="custody-container p-s4 lg:px-s12 lg:max-w-full max-w-xs">
    <h3 class="subheadline-large">{{$t('custody_modal.title')}}</h3>
    <p class="body-text-large mt-s24">{{$t('custody_modal.description')}}</p>
    <ul class="body-text-large mt-s24">
      <template v-for="item of content">
        <li :key="item.id">{{item.id}}. {{item.text}}</li>
      </template>
    </ul>
    <ButtonV2 class="mt-s24 w-full"
    testId="btn-close"
    :label="$t('custody_modal.button_close')" version="primary" @onClick="$emit('close')" />
  </div>
</template>
<script>
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'Custody',
  components: {
    ButtonV2
  },
  computed: {
    content(){
      return [
        { id: 1, text: this.$t('custody_modal.second_bullet_item') },
      ];
    }
  }
};
</script>
<style scoped></style>
